import React from 'react'

import { useEvents } from '../../Hooks/useEvents'
import GroupedEventCard from '../GroupedEventCard'
import { Wrapper } from './styled'

export const Overview = () => {
    const { events: groupedEvents } = useEvents()

    return (
        <Wrapper>
            {groupedEvents.map((g: any) => {
                const first = g.slice(0, 1)[0]
                return <GroupedEventCard key={'group_' + first.id} groupedEvent={g} />
            })}
        </Wrapper>
    )
}
