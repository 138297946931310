export enum EventCategory {
    THEATER = 'theater',
    FILM = 'film'
}
export enum EventSaleStatus {
    OPEN = 'reserveren',
    SOLD_OUT = 'uitverkocht',
    NOT_AVAILABLE = ''
}
export interface IEvent {
    id: number
    category: EventCategory
    from: string
    until: string
    title: string
    subtitle: string
    salestatus: EventSaleStatus
    location_name: string
    location_id: number
    genre: string
}
