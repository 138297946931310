import React from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { ApiAgent, ApiCall } from '../../api'
import { apiAgentId } from '../../api/container'
import Content from '../../Components/Content'
import Header from '../../Components/Header'
import { useService } from '../../Container/ContainerContext'
import { settingsId } from '../../Settings/container'
import { Settings as ISettings } from '../../Settings/Settings'

const Select = styled.select`
    font-size: 1.5rem;
    width: 500px;
    height: 50px;
`

const SelectContainer = styled.div`
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 50px;
`

export const Settings = () => {
    const apiAgent = useService<ApiAgent>(apiAgentId)
    const settings = useService<ISettings>(settingsId)
    const { data: printerData } = useQuery([ApiCall.PRINTERS], apiAgent.getPrinters)
    const { data: terminalData } = useQuery([ApiCall.TERMINALS], apiAgent.getTerminals)

    const printers = printerData?.printers ?? []
    const terminals = terminalData?.terminals ?? []

    console.log(settings.getPrinter())
    console.log(settings.getTerminal())

    const changePrinter = (e: any) => {
        settings.setPrinter(e.target.value)
    }

    const changeTerminal = (e: any) => {
        settings.setTerminal(e.target.value)
    }

    return (
        <>
            <Header />
            <Content>
                {printers.length > 0 ? (
                    <SelectContainer>
                        Printer:
                        <br />
                        <Select onChange={changePrinter} defaultValue={settings.getPrinter() ?? ''}>
                            <option value="">Selecteer een printer</option>
                            {printers.map((p: any) => (
                                <option key={p.id} value={p.id}>
                                    {p.name}
                                </option>
                            ))}
                        </Select>
                    </SelectContainer>
                ) : (
                    <p>Geen printers gevonden</p>
                )}

                {terminals.length > 0 ? (
                    <SelectContainer>
                        Terminal:
                        <br />
                        <Select
                            onChange={changeTerminal}
                            defaultValue={settings.getTerminal() ?? ''}
                        >
                            <option value="">Selecteer een terminal</option>
                            {terminals.map((t: any) => (
                                <option key={t.id} value={t.id}>
                                    {t.name}
                                </option>
                            ))}
                        </Select>
                    </SelectContainer>
                ) : (
                    <p>Geen terminals gevonden</p>
                )}
            </Content>
        </>
    )
}
