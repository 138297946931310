import React from 'react'

import { EventCategory } from '../../Model'
import { Actions, BackButton, Header as InnerHeader, Logo, Spacer, Wrapper } from './styled'

export const Header = ({ onBackButton }: { onBackButton?: () => void }) => (
    <Wrapper>
        <InnerHeader>
            <Logo />
            {onBackButton && (
                <Actions>
                    <BackButton type={EventCategory.FILM} onClick={onBackButton}>
                        Terug
                    </BackButton>
                </Actions>
            )}
        </InnerHeader>
        <Spacer />
    </Wrapper>
)
