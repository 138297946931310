import Bottle from 'bottlejs'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { apiAgentModule } from './api/container'
import { App } from './App'
import { configModule } from './Config/container'
import { ContainerContextProvider } from './Container/ContainerContext'
import { settingsModule } from './Settings/container'
import { PreflightGlobalStyles } from './styles/Preflight'

export const getContainerModules = () => [configModule, settingsModule, apiAgentModule]

export const initialize = async ({ container }: { container: Bottle }) => {}

const queryClient = new QueryClient()

export const renderApp = ({ container }: { container: Bottle }) => {
    return (
        <ContainerContextProvider value={container}>
            <QueryClientProvider client={queryClient}>
                <PreflightGlobalStyles />
                <Router>
                    <App />
                </Router>
            </QueryClientProvider>
        </ContainerContextProvider>
    )
}
