import { useQuery } from 'react-query'

import { ApiAgent, ApiCall } from '../api'
import { apiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'
import { IGetRanksRequest } from '../Model'

export const useRanks = (eventId: number | null) => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const { data, status } = useQuery(
        [ApiCall.RANKS, { eventId: eventId }],
        (_, props: IGetRanksRequest) => apiAgent.getRanks(props),
        {
            enabled: eventId !== null
        }
    )

    return { data, status }
}
