import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import Button from '../Button'
import { ButtonProps } from '../Button/styled'

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
`

export const Event = styled.div``
export const EventInfo = styled.div`
    padding: 30px;
`

export const EventImage = styled(motion.div)`
    img {
        width: 100%;
    }
`

export const EventTitle = styled.div`
    font-size: 2em;
    font-weight: 600;
`
export const EventSubTitle = styled.div`
    font-size: 1.5em;
    font-weight: 400;
`

export const LocationName = styled.div``

export const EventDuration = styled.div``

export const EventDates = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    margin-top: 10px; ;
`

export const EventDate = styled(Button)<{ selected?: boolean; soldOut?: boolean }>`
    color: black;
    border: 1px solid black;
    background-color: white;

    ${({ selected = false }) =>
        selected
            ? css`
                  background-color: #d40f14;
                  border-color: #d40f14;
                  color: white;
              `
            : ''}

    ${({ soldOut = false }) =>
        soldOut
            ? css`
                  background-color: gray;
                  border: 1px solid gray;
              `
            : ''}
`

export const RankSelection = styled.div`
    margin-top: 40px;
`

export const RankText = styled.div`
    font-weight: bold;
`
export const Ranks = styled.div`
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 10px;

    /* compensate for margin-bottom 10px on Rank */
    margin-bottom: -10px;
`
export const Rank = styled(Button)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 10px;
`

export const PriceCodeSelection = styled.div`
    margin-top: 40px;
`
export const PriceCodeText = styled.div`
    font-weight: bold;
`
export const PriceCodeCell = styled.div<{ middle?: boolean }>`
    padding: 10px 0;
    border-top: 1px solid grey;
    display: flex;
    align-items: center;
    overflow: hidden;
    ${({ middle }) => (middle ? `justify-content: center;` : '')}
`

export const PriceCodes = styled.div`
    margin-top: 10px;
    display: grid;
    grid-template-columns: 8fr 2fr 1fr 1fr 1fr;
    margin-top: 10px;

    border-bottom: 1px solid grey;
`

export const SelectedAmount = styled.div`
    font-weight: bold;
`

export const AmountButton = styled(Button)`
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`

export const PriceCodeName = styled.div`
    padding-right: 10px;
`
export const Price = styled.div`
    font-weight: bold;
`

export const FinalizeInfo = styled.div`
    width: 300px;
    margin-left: auto;
    margin-right: 0;
    padding: 30px;
`
export const TotalPrice = styled.div`
    font-weight: 600;
    font-size: 2em;
    text-align: right;
`
export const FinalizeButton = styled(Button)<{ disabled: boolean } & ButtonProps>`
    width: 200px;
    background-color: grey;
    border-color: grey;
    ${({ disabled }) =>
        !disabled
            ? `
            background-color: #d40f14;
            border-color: #d40f14;
            `
            : ``}

    margin-left: auto;
    margin-right: 0;
`

export const LoadingWrapper = styled.div`
    width: calc(100vw - 12px);
    height: calc(100vh - 91px);
    display: flex;
    align-items: center;
    justify-content: center;
`
export const Loading = styled.div`
    width: 137px;
    height: 137px;
    background-image: url(/spinner.svg);
`
