import { bindToFactory } from '../Container/api'
import { ApiAgent } from './ApiAgent'

export const apiAgentId = 'apiAgent'
export const apiAgentModule = bindToFactory(apiAgentId, ({ config, settings }) => {
    return new ApiAgent({
        apiBaseUrl: config.apiBaseUrl,
        token: config.token,
        settings
    })
})
