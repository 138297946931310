import React from 'react'

import Content from '../../Components/Content'
import Header from '../../Components/Header'
import { Overview } from '../../Components/Overview/Overview'

export const Home = () => {
    return (
        <>
            <Header />
            <Content>
                <Overview />
            </Content>
        </>
    )
}
