import React, { useEffect, useMemo, useState } from 'react'

import ProgressBar from '../../../ProgressBar'
import { Popup, PopupContent, PopupTitle, Wrapper } from './styled'

const noop = () => {}

export const Overlay = ({
    title,
    children,
    progressBarTime,
    onProgressExpired = noop,
    role = 'info'
}: {
    title: string
    children?: React.ReactNode
    progressBarTime?: number
    onProgressExpired?: () => void
    role?: 'info' | 'success' | 'error'
}) => {
    const [progress, setProgress] = useState(0)

    const startTime = useMemo(() => {
        return new Date().getTime()
    }, [])

    useEffect(() => {
        if (progressBarTime) {
            const interval = setInterval(() => {
                const diff = (new Date().getTime() - startTime) / 1000
                const p = Math.min((diff / progressBarTime) * 100, 100)
                setProgress(p)
                if (p >= 100) {
                    onProgressExpired()
                }
            }, 500)
            return () => clearInterval(interval)
        }
    }, [startTime, progressBarTime, onProgressExpired])

    return (
        <Wrapper>
            <Popup>
                <PopupTitle role={role}>{title}</PopupTitle>
                <PopupContent>
                    {children}
                    {progressBarTime && <ProgressBar bgcolor="#d40f14" completed={progress} />}
                </PopupContent>
            </Popup>
        </Wrapper>
    )
}
