// import { motion } from 'framer-motion'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Bestel from './Pages/Bestel'
import Home from './Pages/Home'
import Settings from './Pages/Settings'

// const variants = {
//     pageAnimate: { opacity: 1, x: 0 },
//     pageInitial: { opacity: 0, x: '-100%' }
// }

// const variants = {
//     pageInitial: {
//         opacity: 0
//     },
//     pageAnimate: {
//         opacity: 1
//     }
// }
export const App = () => {
    // const location = useLocation()
    return (
        // <motion.div
        //     key={location.pathname}
        //     initial="pageInitial"
        //     animate="pageAnimate"
        //     variants={variants}
        // >
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/bestel/:eventId" component={Bestel} />
            <Route path="/settings" component={Settings} />
        </Switch>
        // </motion.div>
    )
}
