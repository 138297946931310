import axios from 'axios'
import { serialize } from 'object-to-formdata'

import {
    ApiResponse, IEvent, IGetPriceCodesRequest, IGetRanksRequest, IPriceCode, IRank
} from '../Model'
import { Settings } from '../Settings/Settings'

export class ApiAgent {
    protected apiBaseUrl: string
    protected token: string
    protected settings: Settings

    constructor({
        apiBaseUrl,
        token,
        settings
    }: {
        apiBaseUrl: string
        token: string
        settings: Settings
    }) {
        this.apiBaseUrl = apiBaseUrl
        this.token = token
        this.settings = settings
    }

    startPayment = async (paymentInfo: {
        eventId: number
        rank: number
        priceCodes: { [x: number]: number }
    }) => {
        const result = await axios.post(
            `${this.apiBaseUrl}/kiosk/reservation_save`,
            {
                reservation: {
                    printer_id: this.settings.getPrinter(),
                    pin_terminal: this.settings.getTerminal(),
                    voorstelling_id: paymentInfo.eventId,
                    rang_id: paymentInfo.rank,
                    pricecodes: paymentInfo.priceCodes
                }
            },
            {
                headers: {
                    'login-token': this.token
                }
            }
        )

        return result.data
    }

    getPrinters = async () => {
        const result = await axios.get(`${this.apiBaseUrl}/kiosk/get_printers`, {
            headers: {
                'login-token': this.token
            }
        })

        return result.data
    }

    getTerminals = async () => {
        const result = await axios.get(`${this.apiBaseUrl}/kiosk/get_terminals`, {
            headers: {
                'login-token': this.token
            }
        })

        return result.data
    }

    getEvents = async (): Promise<ApiResponse<IEvent[]>> => {
        const result = await axios.post(
            `${this.apiBaseUrl}/framework/public/ajax/site/show.php?action=getShows`
        )

        if (result.status === 200 && result.data.status === 'OK') {
            return {
                success: true,
                data: result.data.message.map((event: any) => ({
                    id: event.id,
                    category: event.category,
                    from: event.from,
                    until: event.until,
                    title: event.title,
                    subtitle: event.subtitle,
                    salestatus: event.salestatus,
                    location_name: event.location_name,
                    location_id: event.location_id,
                    genre: event.genre
                }))
            }
        }
        return { success: false }
    }

    getRanks = async ({ eventId }: IGetRanksRequest): Promise<ApiResponse<IRank[]>> => {
        const result = await axios.post(
            `${this.apiBaseUrl}/framework/public/ajax/site/show.php?action=getRanks&showId=${eventId}`
        )

        if (result.status === 200 && result.data.status === 'OK') {
            return {
                success: true,
                data: result.data.message.map((rank: any) => ({
                    id: rank.id,
                    name: rank.title,
                    price: rank.price,
                    order: rank.volgorde,
                    availableSeats: rank.seats_available
                }))
            }
        }

        return {
            success: false
        }
    }

    getPricecode = async ({
        eventId,
        rankId,
        amount
    }: IGetPriceCodesRequest): Promise<ApiResponse<IPriceCode[][]>> => {
        const result = await axios.post(
            `${this.apiBaseUrl}/framework/public/ajax/site/show.php?action=getPriceCodes`,
            serialize({
                showId: eventId,
                rankId,
                amount,
                reservationMethodId: 12
            }),
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        )

        if (result.status === 200 && result.data.status === 'OK') {
            return {
                success: true,
                data: result.data.message.map((p: any) =>
                    p.map((pricecode: any) => ({
                        id: parseInt(pricecode.id),
                        name: pricecode.label,
                        price: parseFloat(pricecode.prijs),
                        surcharge: pricecode.toeslag
                    }))
                )
            }
        }

        return {
            success: false
        }
    }

    getImages = async (): Promise<ApiResponse<{ [x: number]: string }>> => {
        const result = await axios.get(`https://bestellen.chasse.nl/peppered/images.php`)

        if (result.status === 200) {
            return {
                success: true,
                data: result.data.shows
            }
        }

        return {
            success: false
        }
    }
}
