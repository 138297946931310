import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useImage } from '../../Hooks'
import { EventCategory, IEvent } from '../../Model/Event'
import {
    EventDate, EventDates, EventDuration, EventInfo, EventLocation, EventSubTitle, EventTitle,
    Image, ImageContainer, ImageTag, Wrapper
} from './styled'

export const GroupedEventCard = ({ groupedEvent }: { groupedEvent: IEvent[] }) => {
    const history = useHistory()

    const first = groupedEvent.slice(0, 1)[0]
    const { image } = useImage(first.id)

    return (
        <Wrapper>
            <ImageContainer
                onClick={() => {
                    history.push('/bestel/' + first.id)
                }}
            >
                <Image>{image && <img alt="" src={image} />}</Image>
                <ImageTag type={first.category}>
                    {first.category === EventCategory.THEATER ? 'Theater' : 'Cinema'}
                </ImageTag>
            </ImageContainer>
            <EventInfo
                onClick={() => {
                    history.push('/bestel/' + first.id)
                }}
            >
                <EventTitle>{first.title}</EventTitle>
                <EventSubTitle>{first.subtitle}</EventSubTitle>
                <EventLocation>{first.location_name}</EventLocation>
                {first.until && (
                    <EventDuration>
                        Speelduur: {moment(first.until).diff(first.from, 'minutes')} minuten
                    </EventDuration>
                )}
            </EventInfo>
            <EventDates>
                {groupedEvent.map((s: IEvent) => (
                    <EventDate
                        type={first.category}
                        soldOut={s.salestatus === 'uitverkocht'}
                        onClick={() => {
                            s.salestatus !== 'uitverkocht' && history.push('/bestel/' + s.id)
                        }}
                        key={'bestel_' + s.id}
                    >
                        {moment(s.from).format('H:mm')}
                    </EventDate>
                ))}
            </EventDates>
        </Wrapper>
    )
}
