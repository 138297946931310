import { find } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useEvent, useEvents, useImage, useRanks } from '../../Hooks'
import { usePriceCodes } from '../../Hooks/usePriceCodes'
import { useStartPayment } from '../../Hooks/useStartPayment'
import { EventCategory, IEvent } from '../../Model'
import { getFloorplanImageUrl } from '../../Utils/floorplanImage'
import { priceFormatter } from '../../Utils/numbers'
import Overlay from './Component/Overlay'
import {
    AmountButton, Event, EventDate, EventDates, EventDuration, EventImage, EventInfo, EventSubTitle,
    EventTitle, FinalizeButton, FinalizeInfo, Loading, LoadingWrapper, LocationName, Price,
    PriceCodeCell, PriceCodeName, PriceCodes, PriceCodeSelection, PriceCodeText, Rank, Ranks,
    RankSelection, RankText, SelectedAmount, TotalPrice, Wrapper
} from './styled'

export const EventDetail = ({
    eventId,
    floorplanBaseUrl
}: {
    eventId: number
    floorplanBaseUrl?: string
}) => {
    const history = useHistory()
    const [successWindowOpen, setSuccessWindowOpen] = useState(false)
    const [errorWindowOpen, setErrorWindowOpen] = useState(false)
    const [selectedRank, setSelectedRank] = useState<null | number>(null)
    const [selectedAmounts, setSelectedAmounts] = useState<{
        [x: number]: number
    }>({})
    const [paymentActive, setPaymentActive] = useState(false)

    const hasSelectedAmount = () => !!find(selectedAmounts, (s) => s > 0)

    const {
        status: paymentStatus,
        data: paymentData,
        mutateAsync: paymentMutation
    } = useStartPayment()

    const { events: groupedEvents } = useEvents()

    const groupedEvent = useMemo(() => {
        return groupedEvents.find((g) => {
            return g.filter((e: IEvent) => e.id === eventId).length > 0
        })
    }, [groupedEvents, eventId])

    const { status: imageStatus, image } = useImage(eventId)
    const { status: eventStatus, event } = useEvent(eventId)
    const { status: ranksStatus, data: ranksData } = useRanks(eventId)
    const { status: priceCodeStatus, data: priceCodeData } = usePriceCodes(eventId, selectedRank)

    const ranks = useMemo(() => {
        if (ranksStatus === 'success' && ranksData?.success === true) {
            return ranksData.data.filter((r) => r.price > 0)
        }
        return []
    }, [ranksData, ranksStatus])

    const priceCodes = useMemo(() => {
        if (priceCodeStatus === 'success' && priceCodeData?.success === true) {
            return priceCodeData.data[0]
        }
        return []
    }, [priceCodeData, priceCodeStatus])

    useEffect(() => {
        if (ranks.length === 1) {
            setSelectedRank(ranks[0].id)
        }
    }, [ranks])

    const selectRank = (rankId: number) => {
        setSelectedRank(rankId)
    }

    useEffect(() => {
        setSelectedAmounts({})
    }, [event, selectedRank])

    const totalPrice = useMemo(() => {
        let price = 0
        priceCodes.forEach((pc) => {
            if (selectedAmounts[pc.id]) {
                price += selectedAmounts[pc.id] * pc.price
            }
        })
        return price
    }, [priceCodes, selectedAmounts])

    const startPayment = async () => {
        if (selectedRank !== null && hasSelectedAmount()) {
            setPaymentActive(true)

            await paymentMutation({
                eventId,
                rank: selectedRank,
                priceCodes: selectedAmounts
            })

            setPaymentActive(false)
        }
    }

    useEffect(() => {
        if (paymentStatus === 'success' && paymentData.success === true) {
            setSuccessWindowOpen(true)
        } else if (paymentStatus === 'success' && paymentData.success === false) {
            setErrorWindowOpen(true)
        }
    }, [paymentStatus, paymentData])

    useEffect(() => {
        if (eventStatus !== 'idle' && eventStatus !== 'loading' && event === null) {
            history.push('/')
        }
    }, [eventStatus, event, history])

    const floorplanUrl = useMemo(() => {
        if (event && floorplanBaseUrl !== undefined) {
            return getFloorplanImageUrl({
                baseUrl: floorplanBaseUrl,
                locationId: event.location_id,
                category: event.category
            })
        }
        return null
    }, [event, floorplanBaseUrl])

    return (
        <>
            {eventStatus === 'loading' || ranksStatus === 'loading' || imageStatus === 'loading' ? (
                <LoadingWrapper>
                    <Loading />
                </LoadingWrapper>
            ) : event === null ? (
                <div>Event niet gevonden!!!!</div>
            ) : (
                <Wrapper>
                    {paymentActive && (
                        <Overlay progressBarTime={45} title="Betaling">
                            <p>Je betaling is gestart. Volg de instructies op de pinautomaat.</p>
                            <p>
                                Om je betaling te annuleren, druk je op rode "stop" knop van de
                                pinautomaat.
                            </p>
                        </Overlay>
                    )}
                    {successWindowOpen && (
                        <Overlay
                            role="success"
                            title="Betaald"
                            progressBarTime={5}
                            onProgressExpired={() => {
                                setSuccessWindowOpen(false)
                                history.push('/')
                            }}
                        >
                            <p>Je betaling is verwerkt en de kaarten zijn geprint.</p>
                        </Overlay>
                    )}
                    {errorWindowOpen && (
                        <>
                            {paymentData.code >= 4e6 && paymentData.code < 5e6 ? (
                                <Overlay
                                    role="error"
                                    title="Fout"
                                    onProgressExpired={() => {
                                        setErrorWindowOpen(false)
                                        history.push('/')
                                    }}
                                >
                                    <p>Het printen van de kaarten is niet gelukt.</p>

                                    <p>
                                        Je kunt de kaarten afhalen aan de kassa onder
                                        reserveringsnummer: {paymentData.reservationId}
                                    </p>
                                </Overlay>
                            ) : (
                                <Overlay
                                    role="error"
                                    title="Fout"
                                    progressBarTime={5}
                                    onProgressExpired={() => {
                                        setErrorWindowOpen(false)
                                    }}
                                >
                                    <p>Je reservering is niet afgerond met de volgende reden:</p>
                                    <p>{paymentData.error}</p>
                                </Overlay>
                            )}
                        </>
                    )}
                    <Event>
                        <div style={{ height: '398px', position: 'relative' }}>
                            {floorplanBaseUrl && floorplanUrl ? (
                                <EventImage
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0
                                    }}
                                    key={'event_floorplan'}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    {<img alt="" src={floorplanUrl} />}
                                </EventImage>
                            ) : image ? (
                                <EventImage>{<img alt="" src={image} />}</EventImage>
                            ) : null}
                        </div>
                        <EventInfo>
                            <EventTitle>{event.title}</EventTitle>
                            <EventSubTitle>{event.subtitle}</EventSubTitle>
                            <LocationName>{event.location_name}</LocationName>
                            {event.until && (
                                <EventDuration>
                                    Speelduur: {moment(event.until).diff(event.from, 'minutes')}{' '}
                                    minuten
                                </EventDuration>
                            )}

                            <EventDates>
                                {groupedEvent.map((s: IEvent) => (
                                    <EventDate
                                        type={s.category}
                                        soldOut={s.salestatus === 'uitverkocht'}
                                        onClick={() => {
                                            s.salestatus !== 'uitverkocht' &&
                                                history.push('/bestel/' + s.id)
                                        }}
                                        key={'bestel_' + s.id}
                                        selected={s.id === eventId}
                                    >
                                        {moment(s.from).format('H:mm')}
                                    </EventDate>
                                ))}
                            </EventDates>

                            {ranks.length > 1 && (
                                <RankSelection>
                                    <RankText>Selecteer een rang:</RankText>
                                    <Ranks>
                                        {ranks.map((r) => (
                                            <Rank
                                                type={event.category}
                                                active={r.id === selectedRank}
                                                onClick={(e) => selectRank(r.id)}
                                                key={r.id}
                                            >
                                                {r.name}
                                            </Rank>
                                        ))}
                                    </Ranks>
                                </RankSelection>
                            )}
                            {priceCodes.length > 0 && (
                                <PriceCodeSelection>
                                    <PriceCodeText>
                                        Selecteer een aantal voor de gewenste prijscode:
                                    </PriceCodeText>

                                    <PriceCodes>
                                        {priceCodes.map((p, index) => (
                                            <React.Fragment key={`pricecode_${p.id}_${index}`}>
                                                <PriceCodeCell key={`name_${p.id}_${index}`}>
                                                    <PriceCodeName>{p.name}</PriceCodeName>
                                                </PriceCodeCell>
                                                <PriceCodeCell
                                                    middle
                                                    key={`price_${p.id}_${index}`}
                                                >
                                                    <Price>{priceFormatter.format(p.price)}</Price>
                                                </PriceCodeCell>
                                                <PriceCodeCell middle key={`min_${p.id}_${index}`}>
                                                    <AmountButton
                                                        type={event.category}
                                                        onClick={() => {
                                                            setSelectedAmounts({
                                                                ...selectedAmounts,
                                                                [p.id]: Math.max(
                                                                    (selectedAmounts[p.id] ?? 0) -
                                                                        1,
                                                                    0
                                                                )
                                                            })
                                                        }}
                                                    >
                                                        -
                                                    </AmountButton>
                                                </PriceCodeCell>
                                                <PriceCodeCell
                                                    middle
                                                    key={`amount_${p.id}_${index}`}
                                                >
                                                    <SelectedAmount>
                                                        {selectedAmounts[p.id] ?? 0}
                                                    </SelectedAmount>
                                                </PriceCodeCell>
                                                <PriceCodeCell middle key={`plus_${p.id}_${index}`}>
                                                    <AmountButton
                                                        type={event.category}
                                                        onClick={() => {
                                                            setSelectedAmounts({
                                                                ...selectedAmounts,
                                                                [p.id]: Math.min(
                                                                    (selectedAmounts[p.id] ?? 0) +
                                                                        1,
                                                                    10
                                                                )
                                                            })
                                                        }}
                                                    >
                                                        +
                                                    </AmountButton>
                                                </PriceCodeCell>
                                            </React.Fragment>
                                        ))}
                                    </PriceCodes>
                                </PriceCodeSelection>
                            )}
                        </EventInfo>
                    </Event>

                    <FinalizeInfo>
                        <TotalPrice>Totaal: {priceFormatter.format(totalPrice)}</TotalPrice>
                        <FinalizeButton
                            type={EventCategory.THEATER}
                            disabled={!hasSelectedAmount()}
                            onClick={startPayment}
                        >
                            Bestellen
                        </FinalizeButton>
                    </FinalizeInfo>
                </Wrapper>
            )}
        </>
    )
}
