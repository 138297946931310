import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { ApiAgent, ApiCall } from '../api'
import { apiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'

export const useEvent = (eventId: number | null) => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const { data, status } = useQuery([ApiCall.EVENTS], apiAgent.getEvents, {
        enabled: eventId !== null
    })

    const event = useMemo(() => {
        if (status === 'success' && data?.success === true) {
            return data.data.filter((e) => e.id === eventId)[0] ?? null
        }
        return null
    }, [data, status, eventId])

    return { status, event }
}
