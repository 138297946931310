import styled from 'styled-components'

import { EventCategory } from '../../Model'

export type ButtonProps = {
    type: EventCategory
    disabled?: boolean
    active?: boolean
}

export const Button = styled.div<ButtonProps>`
    text-align: center;
    background-color: ${({ type, disabled = false, active = true }) =>
        disabled
            ? 'grey'
            : active === false
            ? 'white'
            : active && type === EventCategory.THEATER
            ? '#d40f14'
            : active && type === EventCategory.FILM
            ? '#0074c0'
            : ''};
    border: 1px solid
        ${({ type, disabled = false, active = true }) =>
            disabled
                ? 'grey'
                : active === false
                ? 'black'
                : active && type === EventCategory.THEATER
                ? '#d40f14'
                : active && type === EventCategory.FILM
                ? '#0074c0'
                : ''};

    color: ${({ active }) => (active === false ? 'black' : 'white')};
    padding: 10px 25px;
    border-radius: 3px;
`
