import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Content from '../../Components/Content'
import EventDetail from '../../Components/EventDetail'
import Header from '../../Components/Header'

export const Bestel = () => {
    const { eventId } = useParams<{ eventId: string }>()
    const history = useHistory()
    return (
        <>
            <Header
                onBackButton={() => {
                    history.push('/')
                }}
            />
            <Content>
                <EventDetail eventId={parseInt(eventId)} />
            </Content>
        </>
    )
}
