import { values } from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { ApiAgent, ApiCall } from '../api'
import { apiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'

export const useEvents = () => {
    const apiAgent = useService<ApiAgent>(apiAgentId)
    const { data: eventResponse, status } = useQuery(
        [ApiCall.EVENTS],
        (_) => apiAgent.getEvents(),
        {
            refetchInterval: 30000
        }
    )

    const now = moment() //.format()
    const events = useMemo(() => {
        return status === 'success' && eventResponse?.success === true
            ? eventResponse.data
                  .filter((s: any) => {
                      return (
                          (s.salestatus === 'reserveren' || s.salestatus === 'uitverkocht') &&
                              moment(s.from).isAfter(moment(now)) &&
                              moment(s.from).startOf('day').isSame(moment(now).startOf('day'))
                              //moment(s.from).startOf('day').isSame(moment('2021-05-13').startOf('day'))
                      )
                  })
                  .sort((a: any, b: any) => {
                      if (moment(a.from).isSame(moment(b.from))) return 0
                      return moment(a.from).isBefore(moment(b.from)) ? -1 : 1
                  })
            : []
    }, [
        eventResponse,
        // now,
        status
    ])

    const groupedEvents = useMemo(() => {
        let g: any = {}
        events.forEach((event) => {
            g[event.title] = [...(g[event.title] ?? []), event]
        })
        return values(g)
    }, [events])

    return { status, events: groupedEvents }
}
