import { useQuery } from 'react-query'

import { ApiAgent, ApiCall } from '../api'
import { apiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'

export const useImage = (eventId: number | null) => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const { data, status } = useQuery([ApiCall.IMAGES], apiAgent.getImages, {
        cacheTime: 3600
    })

    return {
        status,
        image:
            status === 'success' && data?.success === true && eventId !== null
                ? data.data[eventId] ?? '/default-image-zuil.gif'
                : '/default-image-zuil.gif'
    }
}
