import React from 'react'

export const ProgressBar = ({ bgcolor, completed }: { bgcolor: string; completed: number }) => {
    const containerStyles = {
        height: 5,
        width: '100%',
        backgroundColor: '#e0e0de',
        borderRadius: 0
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit'
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}></div>
        </div>
    )
}
