import React from 'react'

import { IConfig } from '../../Config/Config'
import { configId } from '../../Config/container'
import { useService } from '../../Container/ContainerContext'
import { EventDetail } from './EventDetail'

export const WiredEventDetail = (props: { eventId: number }) => {
    const config = useService<IConfig>(configId)
    return <EventDetail {...props} floorplanBaseUrl={config.floorplanBaseUrl} />
}
