import Bottle from 'bottlejs'
import React from 'react'

const ContainerContext = React.createContext<any>({})

export const ContainerContextProvider = ContainerContext.Provider
export const ContainerContextConsumer = ContainerContext.Consumer
export const useContainer = function (): Bottle {
    return React.useContext(ContainerContext as any)
}

export const useService = <T>(name: string): T => {
    return React.useContext(ContainerContext).container[name]
}
