import { useQuery } from 'react-query'

import { ApiAgent, ApiCall } from '../api'
import { apiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'
import { IGetPriceCodesRequest } from '../Model'

export const usePriceCodes = (eventId: number | null, rankId: number | null) => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const { data, status } = useQuery(
        [ApiCall.PRICECODES, { eventId: eventId, rankId, amount: 1 }],
        (_, props: IGetPriceCodesRequest) => apiAgent.getPricecode(props),
        {
            enabled: eventId !== null && rankId !== null
        }
    )

    return { data, status }
}
