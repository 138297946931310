/**
 * Overrides for development
 */
const configDev = {
    apiBaseUrl: 'https://bestellen.chasse.nl'
}

/**
 * Overrides for test
 */
const configTest = {}

export const config = {
    apiBaseUrl: 'https://bestellen.chasse.nl',
    floorplanBaseUrl: 'https://bestellen.chasse.nl/media/kiosk',
    token: 'E3Mjk5ZTg4ODQ1ZTU2OGNmMmNkMWExMmFlZjFhM2RlZjY0YTlmOTJhZDkxYz',

    ...(process.env.NODE_ENV === 'development'
        ? configDev
        : process.env.NODE_ENV === 'test'
        ? configTest
        : {})
}

export type IConfig = typeof config
