export class Settings {
    getTerminal() {
        return localStorage.getItem('terminal')
    }

    getPrinter() {
        return localStorage.getItem('printer')
    }

    setTerminal(terminal: string) {
        localStorage.setItem('terminal', terminal)
    }

    setPrinter(printer: number) {
        localStorage.setItem('printer', `${printer}`)
    }
}
