import styled from 'styled-components'

import Button from '../Button'

export const Wrapper = styled.div``

export const Header = styled.div`
    width: 100%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 20px 30px;
    position: fixed;
    z-index: 100;
    background: white;

    display: flex;
    justify-content: space-between;
`
export const Logo = styled.div`
    width: 233px;
    height: 51px;
    background-image: url('/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
`

export const Spacer = styled.div`
    height: 71px;
    width: 100%;
`

export const Actions = styled.div`
    display: flex;
    align-items: center;
`

export const BackButton = styled(Button)`
    width: 100px;
`
