import Bottle from 'bottlejs'
import { once } from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'

import { initContainer } from './Container/api'
import { getContainerModules, initialize, renderApp } from './index.common'

const render = ({ container }: { container: Bottle }) => {
    ReactDOM.render(<>{renderApp({ container })}</>, window.document.getElementById('root'))
}

const container = new Bottle()

declare const window: any
window.container = container.container

const setup = once(() => {
    initContainer(container, getContainerModules())
    initialize({ container })
    render({ container })
})

setup()

declare var module: any
if (module) {
    if (module.hot) {
        module.hot.accept()
    }
}
